// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

$backgroundcolor: rgba(62, 62, 62, 0.22);
$bg-color-load: #F2C58F;;
$bg-color-load-inner: #77B3D8;
$bg-color-load-alternate: #77B3D8;
$size: 100px;
$border-size: 10px;


.loader-container{
	position: absolute;
	display:flex;
	justify-content: center;
	gap: 20px;
	align-items: center;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	background: $backgroundcolor;

	h2{
		color: #77B3D8;
		font-size: 32px;
	}

}


.loader-block {
    position: relative;
    width: $size;
    height: $size;
    overflow: hidden;
	z-index:3;

    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        width: $size - $border-size * 2;
        height: $size - $border-size * 2;
        background: $bg-color-load-inner;
        margin: $border-size;
    }
}

.loader{
    width: $size * 2;
    height: $size * 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    position: absolute;
    top: -50%;
    left: -50%;
    background-color: $bg-color-load-alternate;
    animation: rotate 2s infinite ease-in-out;

    &::before,
    &::after {
        content: "";
        background: $bg-color-load;
    }

    &::after {
        grid-column: 2;
        grid-row: 2;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

