// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

.relative-container{
	position:relative;
	height:100%;
}

.no-auth-scrim{
	position:absolute;
	top:0;
	left:0;
	background:white;
	z-index:5;
}
.scrim{
	position: absolute;
	top:0;
	left:0;
	background: rgba(62, 62, 62, 0.22);
	width:100%;
	height: 100%;

}

.org-item{
	cursor: pointer;
}


