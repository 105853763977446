// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

#detailed-view{
	display:flex;
	justify-content: center;
	align-items: center;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index: 5;
	background:rgba(0, 0, 0, 0.467);

	.detailed-wrapper{
		position:relative;
		box-sizing: border-box;
		padding: 10px 20px;
		overflow-y: auto;
		overflow-x: hidden;
		display:flex;
		flex-direction: column;
		align-items: center;
		width:600px;
		aspect-ratio: 1;
		background:white;
		max-width: 95%;
		max-height: 95%;
		border-radius: 15px;
		font-family: "Poppins", sans-serif;

		.close{
			position: absolute;
			top: 5px;
			right:5px;
			width:30px;
			height:30px;
			cursor: pointer;
			
		}

		.org-name{
			font-size: 24px;
			color: #3B6c7F;
			padding-bottom:.5em;
		}

		.org-info-wrapper{
			display:flex;
			flex-direction: column;
			width:100%;
			gap: 1.5em;

			.org-info{
				display:flex;
				flex-direction: column;
				gap:5px;

				.key{
					font-weight:600;
					font-size: 14px;
					color: #3B6c7F;
				}

				.value{
					font-weight: 300;
					color: #3B6c7F;;
				}
			}
		}
	}

	.detailed-wrapper::-webkit-scrollbar {
		width: 12px;
		border-radius: 15px;
	}

	.detailed-wrapper::-webkit-scrollbar-thumb {
 	 	background-color: #F2C58F;
		border-radius: 15px;
	}
}

#detailed-view.map{
	border-radius:31px;
}
