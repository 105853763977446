// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

.delete-these-icky-buttons-when-not-on-dev{
   position: absolute;
   left: 50%;
   top: 10px;
   transform: translate(-50%, 0);
    button{
        color: black;
        background-color: white;
        border: 1px solid grey;
        font-size: 1rem;
        padding: 0.25rem;
    }
}

.widget {
    // min-width: 100%;
    // min-height:100%;
    // box-sizing: border-box;
    // padding: 10px;
    height: 100%;
    font-family: Arial, sans-serif;
    font-size: 1em;
    position: relative;
}


// Safari has special padding it applies to lists that no one else does >:(
ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.widget .database{
    // min-height: 100%;
    width: 100%;
}
