// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!


.header{
	color: #919191;
    font-family: "Poppins", Sans-serif;
    font-size: 60px;
    font-weight: 600;
	text-align: center;
	margin-bottom:60px;
}

.sub-header{
	color: #919191;
    font-family: "Poppins", Sans-serif;
    font-size: 28px;
    font-weight: 600;
	text-align: center;
	margin-bottom:60px;
}

#auth .body{
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap:20px;

	.input-wrapper{
		position:relative;
		width:100%;
		max-width:350px;
		height:52px;

		label{
			position: absolute;
			top:-7px;
			left: 10px;
			font-size: 16px;
			color: #919191;
			top:32%;
			transition: all 0.3s ease;
			pointer-events: none;
		}

		input{
			max-width:350px;
			box-sizing: border-box;
			align-self: flex-end;
			width:100%;
			height:100%;
			padding: 10px;
			font-family: Roboto, sans-serif;
			border-radius:4px;
			border:none;
			border-bottom: 4px solid #77B3D8;
			padding-top:15px;
			font-size: 16px;
		}

		input:-webkit-autofill{
			transition: background-color 5000s ease-in-out 0s;
			-webkit-background-clip: text;
			box-shadow: inset 0 0 20px 20px white;
		}
	}

	.input-wrapper.active{
		label{
			top: -7px;
			left: 10px;
			font-size: 13px;
			font-weight:bold;
		}

		input:focus{
			border-bottom: 4px solid #F2C58F;
			outline: none;
		}
	
	}

	.button-wrapper{
		display:flex;
		flex-direction: column;
		gap:10px;
	}

	.error-message{
		color:rgb(223, 59, 44);
	}
}
button{
	border:none;
	font-size: 14px;
	color: #919191;
	color:white;
	font-weight: bold;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}

.secondary-btn{
	background: #77B3D8;
}

.primary-btn{
	background:#F2C58F;
}

@media (max-width: 1024px) {
  #auth .header {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  #auth .header {
    font-size: 36px;
  }
}

