// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!



#map-tool{
	width: 100%;
	height: 100%;
}


#map-tool .map-container{
	position:relative;
	width: 706px;
	height: 528px;
	border-radius:31px;
	min-height: 400px;

	.search-bar {
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 250px;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid #ccc;
		font-size: 14px;
		z-index: 10;
	}

	.suggestions {
		position: absolute;
		background: white;
		width: 250px;
		border: 1px solid #ccc;
		border-radius: 5px;
		max-height: 150px;
		overflow-y: auto;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		z-index: 10;
	}

	.suggestions li {
		padding: 10px;
		cursor: pointer;
	}

	.suggestions li:hover {
		background: #F0F0F0;
	}

	article{
		border-radius:31px;
		width:100%;
		height: 100%;
	}

	.title{
		display:flex;
		justify-content: center;
		align-items: center;
	}
}

.login-scrim{
	width: 100%;
	height:100%;
	position: absolute;
	top:0;
	left:0;
	z-index:3;
}

.btn-wrapper{
	display:flex;
	gap: 20px;

	.open-db-btn, .detail-btn{
		margin-top: 10px;
		padding: 10px;
		border-radius: 5px;
		background: #77B3D8;
		border: none;
		color: white;
	}

	.detail-btn{
		background: #F2C58F;
	}
}


@media (max-width: 1200px) {
	#map-tool .map-container {
		width: calc(706px * 0.8); /* Reducing width by 20% */
		height: calc(528px * 0.8); /* Reducing height by 20% */
	}
}

@media (max-width: 600px) {
	#map-tool .map-container {
		width: calc(706px * 0.65); /* Reducing width by 40% */
		height: calc(528px * 0.65); /* Reducing height by 40% */
	}
}

@media (max-width: 480px) {
	#map-tool .map-container {
		width: calc(706px * 0.6); /* Reducing width by 60% */
		height: calc(528px * 0.6); /* Reducing height by 60% */
	}
}

@media (max-width: 440px) {
	#map-tool .map-container {
		width: calc(706px * 0.55);
		height: calc(528px * 0.55);
	}
}

@media (max-width: 400px) {
	#map-tool .map-container {
		width: calc(706px * 0.50);
		height: calc(528px * 0.50);
	}
}
