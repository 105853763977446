// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!
/* ---------------------------------------------------------
 * 1 page containers
 * 	1.1 Buttons
 * 	1.2 -- Custom Scroll bars
 * 	1.3 - Dividers
 * ------------------------------------------------------ */


.icon{
	width: 24px;
	min-width: 10px;
	height: 24px;
	min-height: 10px;
	border-radius: 50%;
	background-color: #FFFFFF;
	.path{
		fill: #000000;
	}
}

/*- 1 -*/

#database-tool,
#comparison-tool{
	--color-white:         #FFFFFFFF;
	--color-secondary:     #F2C58FFF;
	--color-orange-bright: #F8A519FF;
	--color-orange-dark:   #926D41FF;
	--color-blue-bright:   #77B3D8FF;
	--color-primary:       #204F60FF;
	--color-primary-20:    #204F6044;
	--color-black:         #000000FF;
	--color-black-85:      #000000D9;
	--color-black-70:      #000000B8;
	--color-black-50:      #00000088;
	--color-black-25:      #00000044;

	--font-size-10-2-20: clamp(10px, 1.875vw, 1.25rem);
	--font-size-10-3-16: clamp(10px, 3vw, 16px);
	--font-size-12-1-16: clamp(0.75em, 1.75vw, 1em);
	--font-size-12-3-16: clamp(12px, 3vw, 16px);
	--font-size-16-2-20: clamp(1rem, 2.5vw, 1.25rem);
	--font-poppins:     "Poppins", sans-serif;
	--font-open-sans: "Open sans", sans-serif;

	--width-col-left: calc(min(20vw,17.5rem));
	--height-widget: 32rem;
	--height-srch-row: 2.25rem; // Search input
	--height-page-nav: 3rem;    // DB tool pagination nav

	display: grid;
	gap: 0.5rem;
	grid-template-columns: var(--width-col-left) 4px 1fr;
	grid-template-rows: 1fr 3px var(--height-page-nav);

	font-family: var(--font-poppins);
	background-color: var(--color-white);
	overflow: hidden;

	:root{
		font-size: var(--font-size-12-3-16);
	}

	&, & *{
		box-sizing: border-box;
	}

	.grid.container{
		display: grid;
		gap: 0.5rem;
		grid-template-columns: var(--width-col-left) 4px 1fr;
		grid-template-rows: 1fr 3px var(--height-page-nav);
	}
}
/* Force Database tool to cooperate with Wordpress: */
#database-tool{
	height: 100%;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
    display: grid;
}


	/* 1.2 ---------- BUTTONS & Button-like Elements ----------*/

#database-tool,
#comparison-tool{
	button, input, optgroup, select, textarea{
		font-family: var(--font-poppins);
		font-size: 1rem;
		line-height: 1.25rem;
	}

	[type=button], [type=submit],  button{
		outline: 1px solid transparent;
		color: var(--color-black);
		&:focus, &:hover{
			text-decoration: none;
			color: inherit;
			background-color: inherit;
			outline-color: var(--color-primary-20);
		}
	}

	button{
		padding: 0.75em;
		min-height: 1.5em;
		min-width: 4rem;

		&.icon svg.icon,
		&.icon svg.icon rect,
		&.icon svg.icon:disabled,
		&.icon svg.icon rect:disabled{
			fill: none;
		}
		&.icon svg.icon rect.bkg{
			fill: var(--color-secondary);
		}
		&.icon svg.icon path.fill{
			fill: var(--color-black-50);
		}
		&:disabled{
			&.icon svg.icon rect.bkg{
				fill: var(--color-black-25);
			}
			&.icon svg.icon path.fill{
				fill: var(--color-white);
			}
		}
	}


	button.clear-filters{
		display: flex;
		align-items: center;
		width: fit-content;
		gap: 0.5rem;
		border-radius: 3px;
		border: 2px solid var(--color-blue-bright);
		font-size: var(--font-size-10-3-16);
		text-transform: capitalize;
		background-color: transparent;

		svg, span{
			height: 1rem;
			line-height: 1rem;
		}

		svg{
			width: 1rem;
			fill: var(--color-primary)
		}

	}
	@media screen and (max-width: 450px) {
		button.clear-filters{
			gap: 2px;
			width: 4rem;
			justify-content: center;
			padding: 0px;
			height: fit-content;

			svg{
				height: 8px; width: 8px;
			}
		}
	}

	button,
	.section .row.name,
	.filters .row.name,
	.section .option.row,
	.section .option.row label{
		cursor: pointer;
		user-select: none;
		width: 100%;
	}

	/*  1.2 -- Custom Scroll bars ----------------------*/
	.scroll-y, .lines-2, .lines-3, .lines-4{
		overflow-y: scroll;

		@supports (scrollbar-width: auto){
			scrollbar-width: 8px;
			scrollbar-color: var(--color-primary) initial;
			scrollbar-base-color: var(--color-blue-bright);
			scrollbar-darkshadow-color: var(--color-orange-dark);
			border-radius: 4px;
		}

		@supports selector(::-webkit-scrollbar) {
			&::-webkit-scrollbar{
				width: 8px;
				border-radius: 4px;
				border: 1px solid var(--color-primary-20)
			}

			&::-webkit-scrollbar-thumb{
				background: var(--color-primary);
				border-radius: 4px;
			}
		}
	}

	/*  1.3 - Dividers --------------------------------*/
	.divider{
		background-color: var(--color-primary-20);
		height: 2px;
		width:  2px;
		&.v{
			grid-row: 1 / -1;
			grid-column: 2 / span 1;
			height: 100%;
		}

		&.h{
			grid-column: 1 / -1;
			width: 100%;
		}
	}

	/*---------- Global Classes --------------------------*/
	/*---------- Grid & Layout  --------------------------*/

	&, .grid, .col, .row{
		display: grid;
	}

	.col{grid-auto-flow: row;}
	.row{grid-auto-flow: column;}



	&,
	.left-col,
	.database-wrapper{
		min-height: var(--height-widget);
		max-height: calc(100vh - 11rem);
		width: 100%;
	}

	.left-col > .col.pop-out.active{
		height: 100%;
		max-height: calc(100% - 2rem);
	}


	.scroll-container{
		overflow-y: auto;
		min-height: calc( var(--height-widget) - var(--height-srch-row) * 3);
		max-height: calc(100% - 3rem);
		padding-bottom: 1rem;
		padding-right: 0.25rem;
	}

	.chart,
	.database-wrapper{
		grid-column: 3 / -1;
		grid-row: 1 / span 1;
	}

	.chart{
		grid-row: 2 / span 2;
	}

	.page-nav{
		grid-column: 2 / -1;
		grid-row: 3 / -1;
	}

	.left-col{
		grid-template-rows: repeat(3, var(--height-srch-row)) 1fr;
		grid-column: 1 / span 1;
		grid-row: 1 / -1;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}
	/*---------------------------------------------------*/

	/* Left Column, Filters ------------------------------*/

#database-tool,
#comparison-tool{
	.filter, .search, .row, .name, input, svg{
		animation: all 250ms ease-in-out;
		transition: all 125ms ease-in-out;
	}

	.header.name,
	.option, .option input, .option label,
	button, button.clear span, .search, .search input{
		font-size: var(--font-size-12-1-16);
		font-weight: 300;
		color: var(--color-black-70);
	}

	svg.icon .fill{
		fill: var(--color-black-70);
	}

	.search{
		background-color: var(--color-white);
		position: relative;
		align-items: center;

		input{
			padding: clamp(0.5em, 1vw, 0.875em) 2em clamp(0.5em, 1vw, 0.875em) 0.5em;
			border: 2px solid var(--color-primary-20);
			width: 100%;
			outline-offset: -1px;
			border-radius: 3px;
		}

		input:focus, input:active, input:focus-within{
			outline-color: var(--color-primary);
			outline-offset: 2px;
		}

		svg.icon{
			display: block;
			position: relative;
			right:   0.5rem;
			height: 1.25rem;
			width:  1.25rem;
			fill: var(--color-black);
			top: 50%;
			position: absolute;
			right: 0;
			transform: translate(-50%, -50%);
		}
	}
}

#database-tool .left-col{
	// grid-template-rows: 3.25rem 2.25rem 2rem 1fr;
	grid-template-rows: 2.5rem 2rem 2.5rem 1fr;
	gap: 4px;
}

#database-tool,
#comparison-tool{
	.option,
	.filter,
	.filters,
	.section,
	.header.name{
		padding-block: 0.25rem;
	}

	.filters{
		grid-template-rows: 2rem 1fr;
		align-content: start;
		// overflow: hidden;
	}

	svg.icon,
	.search svg.icon,
	.check, input[type="checkbox"]{
		width: 1em;
		height: 1em;
	}

	.filters .row.name,
	.section .row.name{
		outline: 1px solid transparent;
		align-items: center;
		&:hover{
			outline-color: var(--color-primary);
		}
	}

	.option.row {
		border-bottom: 1px solid transparent;
		&:hover{
			border-bottom-color: var(--color-primary);
		}
	}

	.filters .row.name .header.name{
		font-family: var(--font-open-sans);
		font-size: var(--font-size-16-2-20);
		font-weight: 300;
	}



	.filters, .scroll-container {
		.name, .option, .filter.row{
			width: 100%;
			align-content: center;
			justify-content: space-between;
		}

		& > .row.name{padding-right: 10px;}

		.section{
			padding-left: 2px;
			.row{
				padding-left: 0.5rem;
			}
		}

		.option{
			align-items: center;
			grid-template-columns: 1.5em 1fr;
		}

		.check{
			margin: 0 0.5em 0 1px;

			input{
				border: 2px solid var(--color-primary);
			}

			input[type="checkbox"]{
				margin: 0;
				accent-color: var(--color-primary);
			}
		}
	}
}


	/*---------------------------------------------------*/

	/* Content -------------------------------------------*/
#database-tool,
#comparison-tool{
	.scroll-container .name.no-results{
		justify-content: center;
	}
	.content{
		height: 100%;
		max-height: 100;
	}

	.content .scroll-container{
		max-height: calc(100% - 3rem);
		min-height: calc(100% - 3rem);
	}

	.organizations{
		grid-template-rows: repeat(auto-fit, 10rem);
	}

	.org-item{
		position: relative;
		gap: 1rem;
		height: 10rem;
		max-width: 100%;
		overflow: auto hidden;
		padding: 1.25rem;
		grid-template-columns: 1fr 7.5rem;
		color: var(--color-black-70);
		font-weight: 300;
		outline: 1px solid transparent;
		outline-offset: -2px;
		&:hover{
			outline-color: var(--color-primary);
		}

		.col{
			// grid-template-rows: 2rem minmax(1.5rem, 2.5rem) minmax(1.5rem, 2.5rem);
			grid-template-rows: auto;
		}

		.name, .advocacy div, .row.info{
			font-size: var(--font-size-10-3-16);
			font-family: var(--font-open-sans);
		}

		.row.info.website{
			display:flex;
			display:inline-block;
			justify-self: start;;
			width:auto;
		}

		.name{
			font-size: var(--font-size-10-2-20);
			max-height: 2rem;
			max-width: 100%;
			font-weight: 400;
			color: var(--color-black-70);
			overflow: hidden;
			text-overflow: ellipsis;
			align-content: normal;
			text-overflow: ellipsis;
		}

		.advocacy{
			grid-column: 2;
			grid-row: 1 / -1;
			gap: 2px;
			align-items: center;

			.dog-ear, .np{
				position: relative;
				min-width: 7.25rem;    /* == 116px */
				min-height: 2.375rem;  /* ==  38px */
				padding: 0.625rem 1.25rem; /* == 10px 20px */
				background: var(--color-secondary);
			}

			.np{
				background: var(--color-primary-20);
			}
		}

		.lines-2, .lines-3, .lines-4{
			font-size: var(--font-size-10-2-20);
			height: 2.45rem;
			overflow: auto;
		}
	}


	/* Row Below Content ---------------------------------*/
	/* Pagination Nav (Database tool) --------------------*/
	.page-nav {
		width: 100%;
		justify-content: center;
		align-content: center;
		align-items: center;
		padding: .5rem;
		height: var(--height-page-nav);

		button{
			border-color: transparent;
			background: transparent;
			box-sizing: border-box;
			font-weight: 300;
			color: var(--color-black-70);
		}

		button.num{
			padding: 2px;
			height: 2rem;
			width: 2rem;
			position: relative;
			&.active{
				font-weight: 400;
				&::after{
					content: "";
					position: absolute;
					display: block;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: var(--color-primary);
				}
			}
		}


		button.icon{
			min-width:  1rem;
			width:    1.5rem;
			min-height: 1rem;
			height:   1.5rem;
			padding: 2px;
		}

		button:disabled{
			&:hover, &:focus, &:active{
				outline-color: transparent;
				border-color:  transparent;
			}
		}
	}


	/*---------- Chart: X-axis selectors -----------------*/
	#x-axis{
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(auto-fit, 1.5rem);
		gap: 0.5rem;
		height: fit-content;
		width: max-content;
		padding: 0.25rem;
		margin-inline: auto;

		.checkbox{
			justify-content: left;
			grid-column: span 1;
			grid-row: span 1;
			height: 24px;
			align-items: center;
		}
		.checkbox input[type="checkbox"]{
			width:  16px;
			height: 16px;
			background-color: transparent;
			border: 1px solid currentColor;
			border-radius: 4px;
			accent-color: var(--color-primary);
		}
	}
}

// .widget.testing-layout-hint,
.bh-widget-snippet,
#database-tool{
    height: 100%;
	max-height: 100%;
}

#database-tool{
    max-height: 100%;
	width: 100%;
}

.widget.testing-layout-hint{
    min-height: fit-content;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

#database-tool.mobile{
	background-color: red;
}
@media screen and (min-width: 864px) {
	#database-tool{
		min-width: 650px;
	}
}


@media screen and (max-width: 864px) {
	#database-tool{
		min-width: 100px;
		min-height: 4rem;
		--filters-row: 4.75rem;

		.grid.container{
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 2rem 1fr;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			position: relative;
			display: block;
			max-height: calc(100% - 5rem);
		}
		.left-col{
			width: 100%;
			grid-template-columns: 1fr 4rem;
			grid-template-rows: 2.25rem 2rem;
			gap: 0.25rem;
			background-color: var(--color-white);
			height: var(--filters-row);
			max-height: unset;
			min-height: unset;
			display: grid;

			.search.row{
				grid-column: span 2;
				grid-row: 1;
			}

			.filters.col,
			.clear.clear-filters{
				height: 100%;
				grid-row: 2;
			}
			.filters.col{
				grid-column: 1;
				padding-block: unset;
			}
			.clear.clear-filters{
				grid-column: 2;
			}
			.pop-out{
				background-color: var(--color-white);
				position: absolute;
				top: var(--filters-row);
				left: 0;
				width: 100%;
				height: calc(100% - 5rem);
				max-width: 90vw;

				.scroll-container.scroll-y{
					max-height: 100%;
					min-height: 4rem;
					height: 80vh;
					height: fit-content;
					max-height: calc(80vh - 4rem);
				}
			}
			.pop-out.active{
				height: fit-content;
				padding-block: 1rem;
				box-shadow: 0 1px 0 1px var(--color-primary);
				z-index: 1;
			}
		}
		.divider.v{
			display: none;
		}

		.database-wrapper{
			min-height: unset;
			height: calc(100% - 3rem);
			max-height: calc(100vh - 15rem);
		}

		.content{
			border-top:    1px solid var(--color-primary-20);
			border-bottom: 1px solid var(--color-primary-20);

			.scroll-container{
				max-height: 100%;
			}
			// max-height: calc(100% - 8rem);
		}

		.content .organizations{
			grid-template-rows: auto;
		}

		.content .org-item{
			padding: 0.5rem;
			grid-template-columns: 100%;
			height: fit-content;
			min-height: 3rem;
		}

		.org-item .col{
			height: fit-content;
			gap: 0.5rem;
		}

		.org-item .col .advocacy{
			grid-row: 2;
			grid-column: 1;
			display: flex;
		}

		.org-item .col .advocacy div{
			min-width: unset;
			min-height: unset;
			padding: 0.125rem 0.5rem;
			width: fit-content;
		}

	}
}